import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationModel } from '../../app/models/notification-model';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { PermissionTokenDbService } from '../_db/permission-token-db.service';
import { PushTokenModel } from '../models/push-token-model';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  notification$: BehaviorSubject<NotificationModel> = new BehaviorSubject(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private db: PermissionTokenDbService
  ) {}

  requestPermission() {
    this.afMessaging.requestToken
      .pipe(
        filter((token) => token !== null),
        switchMap((token) =>
          this.db
            .getTokenByTokenId(token)
            .pipe(map((res) => ({ tokenFromDb: res, initialToken: token })))
        )
      )
      .subscribe((result) => {
        if (result.tokenFromDb) {
          return;
        }
        const pushToken = new PushTokenModel({
          token: result.initialToken,
          locationId: 's4dyrxmJkv3T8TcN0BuY', //get from usrobject
        });
        this.db.saveToken(pushToken);
      });
  }

  listenToPushNotifications() {
    this.afMessaging.messages.subscribe((message) => {
      this.setNotification({
        body: message.notification.body,
        title: message.notification.title,
      });
    });
  }

  getNotification() {
    return this.notification$.asObservable();
  }

  private setNotification(notification: NotificationModel) {
    this.notification$.next(notification);
  }
}
