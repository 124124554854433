import { Component, OnInit } from '@angular/core';
import { AppUpdateService } from './_services/app-update.service';
import './_shared/date-extension';
import { PushNotificationService } from './_services/push-notification.service';
import { ToastService } from './_services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private updateService: AppUpdateService,
    private toastService: ToastService,
    private pushNotificationService: PushNotificationService
  ) {}
  ngOnInit(): void {
    this.updateService.notifyForAppUpdates();
    this.pushNotificationService.requestPermission();
    this.pushNotificationService.listenToPushNotifications();
    this.toastService.presentToastWhenRecieved();
  }
}
