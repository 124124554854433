import { Injectable, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { NotificationModel } from '../models/notification-model';
import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private notificationService: PushNotificationService,
    private toastController: ToastController
  ) {}

  presentToastWhenRecieved() {
    this.notificationService
      .getNotification()
      .subscribe(async (notification) => {
        if (notification) {
          await this.presentToast(notification);
        }
      });
  }
  async presentToast(notification: NotificationModel) {
    const toast = await this.toastController.create({
      header: notification.title,
      message: notification.body,
      duration: 5000,
      position: 'bottom',
      cssClass: 'toast-message',
      animated: true,
      icon: 'notifications-outline',
      color: 'light',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }
}
