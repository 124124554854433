import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { PushTokenModel } from '../models/push-token-model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionTokenDbService {
  private tokensCollection: AngularFirestoreCollection<PushTokenModel>;

  constructor(private firestore: AngularFirestore) {
    this.tokensCollection = this.firestore.collection<PushTokenModel>('tokens');
  }

  saveToken(token: PushTokenModel) {
    this.tokensCollection.add(Object.assign({}, token));
  }

  getAllTokens(): Observable<PushTokenModel[]> {
    return this.tokensCollection.valueChanges();
  }

  getTokenByLocationId(locationId: string): Observable<PushTokenModel> {
    return this.firestore
      .collection<PushTokenModel>('tokens', (ref) =>
        ref.where('locationId', '==', locationId).limit(1)
      )
      .valueChanges()
      .pipe(map((tokens) => tokens[0]));
  }

  getTokenByTokenId(token: string): Observable<PushTokenModel> {
    return this.firestore
      .collection<PushTokenModel>('tokens', (ref) =>
        ref.where('token', '==', token).limit(1)
      )
      .valueChanges()
      .pipe(
        take(1),
        map((tokens) => tokens[0])
      );
  }
}
