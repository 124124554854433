import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(public updates: SwUpdate, private alertService: AlertService) {}

  public notifyForAppUpdates() {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(async () => {
          await this.alertService.showNewVersionAlert();
        });
    }
  }
}
