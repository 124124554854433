import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  public async showNewVersionAlert() {
    const alert = await this.alertController.create({
      header: 'Nowa wersja aplikacji',
      message:
        'Dostępna jest nowa wersja aplikacji. Naciśnij OK aby odświeżyć aplikację.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => {},
        },
        {
          text: 'Okay',
          id: 'confirm-button',
          handler: () => {
            window.location.reload();
          },
        },
      ],
    });
    await alert.present();
  }
}
