declare global {
  interface Date {
    addDays(days: number): Date;
  }
}

// eslint-disable-next-line space-before-function-paren
Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  const result = date.setDate(date.getDate() + days);
  return new Date(result);
};

export {};
